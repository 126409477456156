<template>
  <div :redraw="redraw">
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th></th>
        <th>
          {{ $t("groupInformationAboutStudentMovementReport.quarter.1") }}
        </th>
        <th>
          {{ $t("groupInformationAboutStudentMovementReport.quarter.2") }}
        </th>
        <th>
          {{ $t("groupInformationAboutStudentMovementReport.quarter.3") }}
        </th>
        <th>
          {{ $t("groupInformationAboutStudentMovementReport.quarter.4") }}
        </th>
        <th>
          {{ $t("groupInformationAboutStudentMovementReport.quarter.yearly") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(dataItem, mIndex) in data" :key="mIndex">
        <td :class="{ sub: dataItem.isSub }">
          {{ $t("groupInformationAboutStudentMovementReport." + dataItem.title) }}
        </td>
        <td>
          {{dataItem.dataItem.quarter1}}
        </td>
        <td>
          {{dataItem.dataItem.quarter2}}
        </td>
        <td>
          {{dataItem.dataItem.quarter3}}
        </td>
        <td>
          {{dataItem.dataItem.quarter4}}
        </td>
        <td>
          {{dataItem.dataItem.yearly}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>  
</template>
<style>
.dropped td {
  background-color: #F64E60;
}

th.rotate {
  writing-mode: vertical-rl;
}

td.sub{
  padding-left: 20px;
}

</style>
<script>
import ApiService from "@/common/api.service";

export default {
  name: "InformationAboutStudentMovementTable",
  components: {
  },
  data() {
    return {
      data:[],
      groupId: -1,
      redraw: 0
    };
  },
  mounted() {
      
  },
  methods: {
    redrawTable(groupId){
      this.groupId = groupId;
      let $this = this;
      
      ApiService.querySecured("schoolReports/informationAboutStudentMovement", {
        params: { groupId: groupId }
      }).then(({data}) => {

        $this.data = [];
        
        $this.addData("quarterStartCount", data.quarterStartCount);
        $this.addData("quarterStartCountKyrgyz", data.quarterStartCountKyrgyz, true);
        $this.addData("quarterStartCountGirls", data.quarterStartCountGirls, true);
        
        $this.addData("leaveDuringQuarterCount", data.leaveDuringQuarterCount);
        $this.addData("leaveDuringQuarterCountKyrgyz", data.leaveDuringQuarterCountKyrgyz, true);
        $this.addData("leaveDuringQuarterCountGirls", data.leaveDuringQuarterCountGirls, true);

        $this.addData("joinDuringQuarterCount", data.joinDuringQuarterCount);
        $this.addData("joinDuringQuarterCountKyrgyz", data.joinDuringQuarterCountKyrgyz, true);
        $this.addData("joinDuringQuarterCountGirls", data.joinDuringQuarterCountGirls, true);

        $this.addData("quarterEndCount", data.quarterEndCount);
        $this.addData("quarterEndCountKyrgyz", data.quarterEndCountKyrgyz, true);
        $this.addData("quarterEndCountKyrgyzGirls", data.quarterEndCountKyrgyzGirls, true);
        $this.addData("quarterEndCountBoys", data.quarterEndCountBoys, true);
        $this.addData("quarterEndCountGirls", data.quarterEndCountGirls, true);

        $this.addData("eduDaysCount", data.eduDaysCount);
        
        
        $this.redraw = Math.random();
      }).catch((e) => {
        alert(e);
      });
    },
    addData(title, dataItem, isSub){
      this.data.push(
          {
            title: title,
            dataItem: dataItem,
            isSub: isSub,
          }
      );
    }
  }
};
</script>
